<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg12">
    <div class="container">
        <div class="inner-title">
            <h3>Contact Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>

<div class="contact-area ptb-100">
    <div class="scetion-title text-center">
        <span>Contact Us</span>
        <h2>Drop Your Message</h2>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
    </div>

    <div class="container">
        <div class="contact-wrap pt-45">
            <div class="contact-wrap-form">
                 <form id="contactForm">
                     <div class="row">
                         <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name">
                            </div>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email">
                            </div>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                            </div>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                            </div>
                         </div>

                         <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" cols="30" rows="8" required placeholder="Your Message"></textarea>
                            </div>
                         </div>

                         <div class="col-lg-12 col-md-12 text-center">
                            <button type="submit" class="default-btn page-btn text-center">Send Message</button>
                         </div>
                     </div>
                 </form>
            </div>
         </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>