<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title">
            <h3>Sign In</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Sign In</li>
            </ul>
        </div>
    </div>
</div>

<section class="sign-in-area ptb-100">
    <div class="container">
        <div class="contact-wrap-form sign-in-width">
            <div class="scetion-title text-center">
                <span>Sign In</span>
                <h2>Sign In to your account!</h2>
                <p>with your social network</p>
                <ul class="sign-social-icon">
                    <li><a href="#"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#"><i class='bx bxl-twitter' ></i></a></li>
                    <li><a href="#"><i class='bx bxl-google-plus'></i></a></li>
                </ul>
            </div>

            <form>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" type="text" name="name" placeholder="Username or Email">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" type="password" name="password" placeholder="Password">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6 form-condition">
                        <div class="agree-label">
                            <input type="checkbox" id="chb1">
                            <label for="chb1">Remember Me</label>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <a class="forget" routerLink="/recover-password">Forgot my password?</a>
                    </div>

                    <div class="col-12 text-center">
                        <button class="default-btn btn-two sign-btn" type="submit">Sign In Now</button>
                    </div>

                    <div class="col-12">
                        <p class="account-desc">Not a member? <a routerLink="/sign-up">Register</a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>
