<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/footer-logo.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/Charles-Brian-Head-Logo.png" alt="Charles Brian Head Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">

                    </ul>

                    <div class="cart-area">
                        <a routerLink="/cart"><i class='bx bx-cart'></i></a>
                    </div>

                </div>
            </nav>
        </div>
    </div>
</div>
