<footer class="footer-area">
    <div class="container">
        <div class="footer-contact">
            <div class="row justify-content-center">

                <div class="col-lg-4 col-md-4">
                    <div class="footer-card">
                        <i class='bx bxs-map'></i>
                        <h3>Bixby, Oklahoma</h3>
                    </div>
                </div>

            </div>
        </div>

        <div class="footer-top-list pb-70">
            <div class="row justify-content-center">

                <div class="col-lg-3 col-md-3">
                    <div class="footer-list">

                        <h3>Quick Links</h3>
                        <ul>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="">Home</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/product">Shop</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-5 col-md-5">
                    <div class="footer-side-list">
                        <h3>Contact Us</h3>

                        <ul>
                            <li><i class='bx bxl-telegram'></i> <a href="mailto:support@charlesbrianhead.com">hello@charlesbrianhead.com</a></li>
                            <li><i class='bx bxs-map'></i> Bixby, Oklahoma</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom">
        <div class="container">

        </div>
    </div>
</footer>
