<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-title">
            <h3>Recover Password</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Recover Password</li>
            </ul>
        </div>
    </div>
</div>

<section class="sign-up-area ptb-100">
    <div class="container">
        <div class="contact-wrap-form sign-in-width">
            <div class="scetion-title text-center">
                <span>Reset Password</span>
                <h2>Reset Your Password!</h2>
                <p class="recover-text">Enter the email of your account to reset the password. Then you will receive a link to email to reset the password. If you have any issue about reset password <a routerLink="/contact">contact us.</a> </p>
            </div>

            <form>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" type="email" name="name" placeholder="Enter Your Email">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <a class="recover-login" routerLink="/sign-in">Sign In in your account</a>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <p class="recover-register">Not a member? <a routerLink="/sign-in">Register</a></p>
                    </div>

                    <div class="col-12 text-center">
                        <button class="default-btn btn-two" type="submit">Reset Password</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>
