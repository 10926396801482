<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title">
            <h3>Products</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Products</li>
            </ul>
        </div>
    </div>
</div>

<section class="product-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Product</span>
            <h2>We Have Some Pre-ready Demo Product</h2>
            <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/product-details"><img src="assets/img/products/products1.jpg" alt="Products Images"></a>

                    <div class="product-content">
                        <a routerLink="/product-details"><h3>Package Design</h3></a>
                        <p><span>$29</span> +vat</p>
                        <div class="product-cart">
                            <ul>
                                <li><a routerLink="/"><i class='bx bx-heart'></i></a></li>
                                <li><a routerLink="/cart"><i class='bx bx-cart'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/product-details"><img src="assets/img/products/products2.jpg" alt="Products Images"></a>

                    <div class="product-content">
                        <a routerLink="/product-details"><h3>T-shirt Design</h3></a>
                        <p><span>$20</span> +vat</p>
                        <div class="product-cart">
                            <ul>
                                <li><a routerLink="/"><i class='bx bx-heart'></i></a></li>
                                <li><a routerLink="/cart"><i class='bx bx-cart'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/product-details"><img src="assets/img/products/products3.jpg" alt="Products Images"></a>

                    <div class="product-content">
                        <a routerLink="/product-details"><h3>Cover Van</h3></a>
                        <p><span>$30</span> +vat</p>
                        <div class="product-cart">
                            <ul>
                                <li><a routerLink="/"><i class='bx bx-heart'></i></a></li>
                                <li><a routerLink="/cart"><i class='bx bx-cart'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/product-details"><img src="assets/img/products/products4.jpg" alt="Products Images"></a>

                    <div class="product-content">
                        <a routerLink="/product-details"><h3>Mug Design</h3></a>
                        <p><span>$10</span> +vat</p>
                        <div class="product-cart">
                            <ul>
                                <li><a routerLink="/"><i class='bx bx-heart'></i></a></li>
                                <li><a routerLink="/cart"><i class='bx bx-cart'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/product-details"><img src="assets/img/products/products5.jpg" alt="Products Images"></a>

                    <div class="product-content">
                        <a routerLink="/product-details"><h3>Book Cover</h3></a>
                        <p><span>$15</span> +vat</p>
                        <div class="product-cart">
                            <ul>
                                <li><a routerLink="/"><i class='bx bx-heart'></i></a></li>
                                <li><a routerLink="/cart"><i class='bx bx-cart'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/product-details"><img src="assets/img/products/products6.jpg" alt="Products Images"></a>

                    <div class="product-content">
                        <a routerLink="/product-details"><h3>Astronaut Cover</h3></a>
                        <p><span>$35</span> +vat</p>
                        <div class="product-cart">
                            <ul>
                                <li><a routerLink="/"><i class='bx bx-heart'></i></a></li>
                                <li><a routerLink="/cart"><i class='bx bx-cart'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="pagination-area">
                    <nav aria-label="Page navigation example text-center">
                        <ul class="pagination">
                            <li class="page-item"><a class="page-link page-links" routerLink="/product"><i class='bx bx-chevrons-left'></i></a></li>
                            <li class="page-item current"><a class="page-link" routerLink="/product">1</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/product">2</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/product">3</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/product"><i class='bx bx-chevrons-right'></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>
