<app-navbar-style-one></app-navbar-style-one>

<section class="product-area pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Tee Shirts and Designs</span>
        </div>

        <div class="row justify-content-center pt-45">
            <div class="col-lg-4 col-md-4">
                <div class="product-card">
                    <a routerLink="/product-details"><img src="assets/img/products/products1.jpg" alt="Products Images"></a>

                    <div class="product-content text-center">
                        <a routerLink="/product-details"><h3>CHARLES BRIAN HEAD Tee Shirt</h3></a>
                        <p><span>$29</span></p>
                        <button type="submit" class="default-btn">
                            <i class="fas fa-cart-plus"></i> Buy Now!
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>
