<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title">
            <h3>Sign Up</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Sign Up</li>
            </ul>
        </div>
    </div>
</div>

<section class="sign-up-area ptb-100">
    <div class="container">
        <div class="contact-wrap-form sign-in-width">
            <div class="scetion-title text-center">
                <span>Sign Up</span>
                <h2>Create An Account!</h2>
                <p>with your social network</p>
                <ul class="sign-social-icon">
                    <li><a href="#"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#"><i class='bx bxl-twitter' ></i></a></li>
                    <li><a href="#"><i class='bx bxl-google-plus'></i></a></li>
                </ul>
            </div>

            <form>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" type="text" name="name" placeholder="Username">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" type="email" name="name" placeholder="Email">
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" type="password" name="password" placeholder="Password">
                        </div>
                    </div>

                    <div class="col-12 text-center">
                        <button class="default-btn btn-two sign-btn" type="submit">Sign Up</button>
                    </div>

                    <div class="col-12">
                        <p class="account-desc">Already have an account? <a routerLink="/sign-in">Sign In</a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>
